import React, { useState } from "react"
import { Box } from "theme-ui"
import { graphql } from "gatsby"
import { Section } from "elements-ui"
import Fade from "react-reveal/Fade"
import { Global } from "@emotion/core"
import { SEO } from "@maker-ui/seo"

import PortableText from "../components/PortableText"
import TeamModal from "../components/TeamModal"

export default ({ data }) => {
  const {
    seo,
    pageTitle,
    _rawBodyText,
    image,
    imageCaptionSub,
    imageCaptionTitle,
    teamMembers,
    teamTitle,
  } = data.sanityAboutPage
  const [activeBio, setBio] = useState(teamMembers[0])
  const [modal, setModal] = useState(false)

  function activateModal(bio) {
    setModal(true)
    setBio(bio)
  }

  return (
    <>
      <SEO title={seo.title} description={seo.description} />
      <Global
        styles={
          modal
            ? {
                body: {
                  overflow: "hidden",
                },
              }
            : null
        }
      />
      <Section
        maxWidth="1250px"
        sx={{ p: 20, pt: [40, 80], h1: { fontSize: [32, 42, 68], m: 0 } }}
      >
        <h1>{pageTitle}</h1>
      </Section>
      <Section
        maxWidth="1250px"
        sx={{
          p: 20,
          display: "grid",
          gridTemplateColumns: ["1fr", ".55fr .45fr"],
          gridGap: 50,
        }}
      >
        <Box
          sx={{
            p: { variant: "paragraph" },
            opacity: 0,
            animation: "fadeInUp ease .8s forwards .2s",
            order: [2, 1],
          }}
        >
          {_rawBodyText && <PortableText blocks={_rawBodyText} />}
          <Box
            as="a"
            href="/capability-statement-2023.pdf"
            target="_blank"
            sx={{
              display: "block",
              marginTop: 30,
              border: "1px solid gainsboro",
              borderRadius: 3,
              padding: "20px",
              textAlign: "center",
              background: "#fbfbfb",
              fontSize: 18,
              fontWeight: "bold",
              color: "#84b2e6",
              transition: "all ease .3s",
              "&:hover": {
                transform: "translateY(-7px)",
                color: "#000",
                boxShadow: "1px 6px 6px rgba(0, 0, 0, 0.06)",
              },
            }}
          >
            Download Our Capability Statement
          </Box>
        </Box>
        <Box
          sx={{
            opacity: 0,
            textAlign: "center",
            animation: "fadeInUp ease .8s forwards .4s",
            pt: [0, 30],
            order: [1, 2],
            img: {
              height: ["auto", 400],
              width: "100%",
              objectFit: "contain",
            },
          }}
        >
          <img src={image.asset.url} alt="Alison Bluestone" />
          <Box
            sx={{ textAlign: "center", mt: 10, h4: { fontSize: 20, mb: 0 } }}
          >
            <h4>{imageCaptionTitle}</h4>
            <div>{imageCaptionSub}</div>
          </Box>
        </Box>
      </Section>
      <Section
        sx={{
          p: ["40px 20px 100px", "60px 20px 180px"],
          h2: {
            fontSize: [36, 48],
            mb: 50,
          },
        }}
      >
        <Fade effect="fadeInUp">
          <h2>{teamTitle}</h2>
          <Box
            sx={{
              display: "grid",
              gridGap: 30,
              gridTemplateColumns: ["1fr 1fr", "repeat(4, 1fr)"],
              textAlign: "center",
            }}
          >
            {teamMembers.map((i, index) => (
              <Box
                as="button"
                key={index}
                className="team-member"
                onClick={(e) => activateModal(i)}
                sx={{
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                  transition: "all ease 0.3s",
                  "&:hover": { transform: "translateY(-5px)" },
                  "&:focus, &:active": {
                    outline: "3px solid #000",
                  },
                  img: {
                    mb: 10,
                    width: "100%",
                  },
                  ".title": {
                    fontFamily: "heading",
                    fontSize: [17, 20],
                    fontWeight: ["bold", 500],
                  },
                  ".position": {
                    fontSize: [15, 17],
                  },
                }}
              >
                <img src={i.profilePicture.asset.url} alt={i.title} />
                <div className="title">{i.title}</div>
                <div className="position">{i.position}</div>
              </Box>
            ))}
          </Box>
        </Fade>
      </Section>
      {modal ? <TeamModal activeBio={activeBio} setModal={setModal} /> : null}
    </>
  )
}

export const query = graphql`
  query {
    sanityAboutPage {
      seo {
        title
        description
      }
      pageTitle
      teamTitle
      teamMembers {
        position
        title
        _rawBio(resolveReferences: { maxDepth: 5 })
        profilePicture {
          asset {
            url
          }
        }
      }
      _rawBodyText(resolveReferences: { maxDepth: 5 })
      imageCaptionSub
      imageCaptionTitle
      image {
        asset {
          url
        }
      }
    }
  }
`
