import React from "react"
import { Box } from "theme-ui"

import PortableText from "../components/PortableText"
import CloseIcon from "../assets/icons/Close"

const TeamModal = ({ activeBio, setModal }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(0, 0, 0, 0.25)",
        opacity: 0,
        animation: "fadeIn ease 0.4s forwards",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: ["1fr", ".3fr .7fr"],
          maxWidth: ["90%", "960px"],
          position: "relative",
          background: "#fff",
          width: "100%",
          height: "100%",
          maxHeight: ["70vh", "500px"],
          gridGap: [0, 30],
          borderTop: "10px solid",
          borderColor: "primary",
          opacity: 0,
          overflowY: ["scroll", "hidden"],
          animation: "fadeInUp ease 0.5s forwards 0.2s",
        }}
      >
        <Box
          as="button"
          onClick={() => setModal(false)}
          sx={{
            fontFamily: "heading",
            position: "absolute",
            cursor: "pointer",
            top: ["5px", "20px"],
            right: ["15px", "30px"],
            border: "none",
            background: "none",
            display: "flex",
            alignItems: "center",
            fontSize: "19px",
            svg: {
              height: 35,
              mr: "5px",
            },
          }}
        >
          <CloseIcon />
          Close
        </Box>
        <Box
          sx={{
            textAlign: "center",
            m: ["70px 20px 0", "50px 20px 30px"],
            img: { height: 250, mb: 20 },
            h4: { color: "#000", m: 0, fontSize: 20 },
            p: { mt: "10px" },
          }}
        >
          <img src={activeBio.profilePicture.asset.url} alt={activeBio.title} />
          <h4>{activeBio.title}</h4>
          <p>{activeBio.position}</p>
        </Box>
        <Box
          sx={{
            overflowY: ["initial", "scroll"],
            m: ["0 20px 30px", "80px 20px 30px"],
            p: { mb: 20 },
          }}
        >
          {activeBio._rawBio && <PortableText blocks={activeBio._rawBio} />}
        </Box>
      </Box>
    </Box>
  )
}

export default TeamModal
